
<template>
  <div id="user-edit-tab-info">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="firstName">First name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                v-validate="'required|alpha_spaces'" placeholder="First name" name="first_name" />
                <span class="text-danger font-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="LastName">Last name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                v-validate="'required|alpha_spaces'" placeholder="Last Name" name="last_name" />
                <span class="text-danger font-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="useremail">Email Address</label>
                <input id="useremail" :readonly="!!data.is_verified" label="Email" v-model="data.email" type="email" v-validate="'required|email'" name="email" class="form-control"/>
                <span class="text-danger font-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" autocomplete="false" type="text" class="form-control"
            v-model="data.username" v-validate="{required: true, regex: '^\\+?([a-z0-9]+)$' }" placeholder="username" name="username" />
            <span class="text-danger font-sm"  v-show="errors.has('username')">{{ errors.first('username') }}</span>
          </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="user-mobile">Mobile</label>
                <input id="user-mobile" type="text" class="form-control" v-model="data.mobile" v-validate="{regex: '^\\+?([0-9+]+)$' }" placeholder="mobile" name="mobile" />
                 <span class="text-danger font-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
            </div>
        </div>
      <div class="col-md-6 d-flex align-items-end">
        <div class="form-group">
          <label class="d-block">Verification</label>
            <b-form-checkbox v-model="data.is_verified" name="is-verified" switch>
              {{data.is_verified? 'Verified' : 'Not Verified'}}
            </b-form-checkbox>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="data.gender"
              :options="genderOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-gender"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      data: {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        mobile: "",
        gender: "",
        status: "",
        is_verified: false,
        role: "",
      },
    }
  },
  computed: {
    user(){
      return this.$store.state.userList.user
    }
  },
  methods: {
    patchUser(){
      this.data = this.$options.filters.patchUpdatables(this.data, this.user)
    },
  },
  created(){
    this.patchUser()
  },
}
</script>
